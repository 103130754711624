<script setup lang="ts">

</script>

<template>
  <h1>Home</h1>
  <p>some new things!</p>
</template>

<style scoped>

</style>