import './assets/main.css'

import { createApp } from 'vue'
import { createPinia } from 'pinia'
import vuetify from './plugins/vuetify'

import App from './App.vue'
import router from './router'
import VueKonva from 'vue-konva'
import '@mdi/font/css/materialdesignicons.css';
import 'vuetify/styles'; // Импортируем стили Vuetify

const app = createApp(App)

app.use(vuetify);
app.use(createPinia());
app.use(router);
//@ts-ignore
app.use(VueKonva);

app.mount('#app');
