<script setup lang="ts">

</script>

<template>
  <h1>Landing</h1>
</template>

<style scoped>

</style>