import axios from 'axios';

const apiClient = axios.create({
    baseURL: '/',
    timeout: 1000,
});

// Добавляем интерсептор для добавления токена в заголовки, если нужно
apiClient.interceptors.request.use(config => {
    const token = localStorage.getItem('auth_token');
    
    console.log('auth token: ', token);
    
    if (token) {
        //config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
}, error => {
    return Promise.reject(error);
});

export default apiClient;
