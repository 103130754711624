<template>
  <v-container>
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-text-field
          v-model="formData.username"
          :rules="nameRules"
          label="Введите имя"
          required
      ></v-text-field>

      <v-text-field
          v-model="formData.password"
          :rules="passwordRules"
          label="Введите пароль"
          required
      ></v-text-field>

      <v-btn @click="sendData" color="primary">Отправить</v-btn>

      <v-alert v-if="responseMessage" type="success" dismissible>
        {{ responseMessage }}
      </v-alert>
    </v-form>
  </v-container>
</template>

<script lang="ts">
  import { defineComponent } from 'vue';
  import apiClient from '@/api';
  
  export default defineComponent({
    props: {
      $refs: {
        type: Object as any,
      }
    },
    data() {
      return {
        valid: false, // Для проверки валидации формы
        formData: {
          username: '',
          password: '',
        },
        responseMessage: '',
        nameRules: [
          (v: string) => !!v || 'Имя обязательно', // Правило: имя должно быть заполнено
          (v: string) => (v && v.length <= 20) || 'Имя должно быть менее 20 символов', // Правило: максимальная длина
        ],
        passwordRules: [
          (v: string) => !!v || 'Пароль не может быть пустым!'
        ]
      };
    },
    methods: {
      async sendData() {
        //@ts-ignore
        if (this.$refs.form.validate()) { // Проверка валидации формы
          try {            
            const response = await apiClient.post('/auth/login', {
              "username": this.formData.username,
              "password": this.formData.password
            });
            
            console.log('result is back!', response);
            // Отправляем данные
            this.responseMessage = response.data.message; // Получаем сообщение с сервера

            const token = response.data.authToken;
            
            console.log('send data method:');
            console.log('data:');
            console.log(response.data);
            
            console.log('CLIENT got token:', token);
            
            localStorage.setItem('auth_token', token);
            localStorage.setItem('access_token', token);
            
          } catch (error) {
            console.error('Ошибка при отправке данных:', error);
            this.responseMessage = 'Ошибка при отправке данных'; // Обрабатываем ошибки
          }
        }
      },
    },
  })
</script>

<style scoped>

</style>